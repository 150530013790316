import React, { Component } from 'react';
import Pdf from 'assets/archives/PrivacyPolicy.pdf';

class PrivacyPolicy extends Component {

  render() {

    return (
        <div className = "PolicyPrivacy">
        <a href={"https://ceabs-cdn.s3.sa-east-1.amazonaws.com/MyUconnectFleet/politica_privacidade.pdf"} target = "_blank">Política de Privacidade</a>
        </div>
    );
  }
}

export default PrivacyPolicy;
